import { Avatar, Badge, Separator, Typography } from '@cannect/new-components/atoms'
import { Button } from '@cannect/new-components/atoms/Button'
import { TooltipText } from '@cannect/new-components/atoms/Tooltip'
import { WeekCalendar } from '@cannect/new-components/molecules/WeekCalendar'
import { TSchedulingPrescriberListItem } from '@cannect/services/resources/scheduling'
import { formatDate } from '@cannect/utils/date'
import { formatToReal } from '@cannect/utils/number'
import { getLettersForName } from '@cannect/utils/user'
import { useScheduling } from 'hooks/useScheduling'
import { PlusCircle } from 'lucide-react'
import { formatAgeGroups, returnVisitText } from 'pages/AppointmentScheduling/schedulingUtils'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { AiOutlineInfoCircle } from 'react-icons/ai'

export const PrescriberDetailCard = ({ prescriberInfo }: { prescriberInfo: TSchedulingPrescriberListItem | null }) => {
  const [showAllPathologies, setShowAllPathologies] = useState(false)
  const { next } = useScheduling()
  const { watch, setValue } = useFormContext()

  const schedulingTime = watch('scheduling_time')
  const schedulingDate = watch('scheduling_date')

  const handleDateSelect = (date: Date) => {
    setValue('scheduling_time', formatDate(date, 'HH:mm'))
    setValue('scheduling_date', formatDate(date, 'yyyy-MM-dd'))
  }

  const selectedDateTime =
    schedulingTime && schedulingDate ? new Date(`${schedulingDate}T${schedulingTime}:00.000-03:00`) : undefined

  const handleAppointmentSchedule = () => {
    if (!schedulingTime || !schedulingDate) return
    setValue('prescriber', prescriberInfo)
    setValue('amountWithDiscount', prescriberInfo?.online_appointment_value)
    setValue('creditAmountWithDiscount', prescriberInfo?.online_appointment_credit_value)
    next()
  }

  const prescriberTimestamps = useMemo(() => {
    if (!prescriberInfo) return []
    return prescriberInfo?.available_date_times
  }, [prescriberInfo])

  if (!prescriberInfo) return null

  const formattedAgeGroups = formatAgeGroups(prescriberInfo.prescriber_age_groups || [])
  const expertises = prescriberInfo.prescriber_expertises || []
  const pathologies = prescriberInfo.prescriber_pathologies || []
  const displayedPathologies = showAllPathologies ? pathologies : pathologies.slice(0, 3)

  return (
    <div className="flex w-full flex-col md:flex-row">
      <div className="flex w-full flex-col items-start rounded-l-2xl bg-white p-8">
        <div className="flex h-full w-full flex-col space-y-8 bg-white">
          <div className="flex gap-6">
            <Avatar.Root className="size-16 shrink-0 md:size-24">
              <Avatar.Image src={prescriberInfo?.photo} alt={prescriberInfo?.professional_name} />
              <Avatar.Fallback>{getLettersForName(prescriberInfo?.professional_name)}</Avatar.Fallback>
            </Avatar.Root>

            <div className="flex flex-col">
              <Typography.Text type="paragraphTwo" weight="semibold" className="max-w-[350px] truncate md:text-2xl">
                {prescriberInfo?.professional_name}
              </Typography.Text>

              <TooltipText content={expertises.map((expertise) => expertise.name).join(' • ')}>
                <div>
                  <Typography.Text type="paragraphTwo" muted weight="semibold" className="line-clamp-2">
                    {expertises.map((expertise) => expertise.name).join(' • ')}
                  </Typography.Text>
                </div>
              </TooltipText>

              <div className="mt-1 flex items-center gap-2">
                <Typography.Text type="captionOne" muted>
                  Atende {formattedAgeGroups}
                </Typography.Text>
                <TooltipText content={prescriberInfo?.prescriber_age_groups?.map((group) => group.name).join(' • ')}>
                  <div>
                    <AiOutlineInfoCircle className="h-4 w-4 cursor-pointer" />
                  </div>
                </TooltipText>
              </div>
            </div>
          </div>

          {displayedPathologies.length > 0 && (
            <div className="item-center flex w-full flex-wrap justify-between gap-2 md:flex-nowrap">
              <div className="flex flex-wrap gap-2">
                {displayedPathologies.map((pathology) => (
                  <Badge
                    key={pathology.id}
                    className="bg-neutral-100 text-secondary-600 [&>span]:max-w-[108px] [&>span]:truncate">
                    {pathology.name}
                  </Badge>
                ))}
              </div>
              {pathologies.length > 3 && (
                <Button
                  className="gap-1 text-xs text-secondary-600"
                  unstyled
                  onClick={() => setShowAllPathologies(!showAllPathologies)}
                  icon={<PlusCircle className="size-3" />}
                  iconPlacement="left">
                  {showAllPathologies ? 'Ver menos' : 'Ver mais'}
                </Button>
              )}
            </div>
          )}

          <Typography.Text type="captionOne" className="!mt-4 text-primary-700" weight="semibold">
            {returnVisitText(prescriberInfo)}
          </Typography.Text>

          <div className="space-y-4">
            {prescriberInfo?.biography && (
              <Typography.Text>
                <div dangerouslySetInnerHTML={{ __html: prescriberInfo.biography }} />
              </Typography.Text>
            )}
          </div>
          <Separator className="!mt-auto" />

          <div className="flex items-center justify-between border-t border-gray-200 pt-4">
            <Badge variant="outline_secondary" size="lg">
              {prescriberInfo?.appointment_duration} min
            </Badge>
            <Badge variant="success" size="lg" rounded="md" className="font-semibold text-primary-1000">
              {formatToReal(prescriberInfo?.online_appointment_value)}
            </Badge>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col gap-8 rounded-r-2xl bg-neutral-50/50 px-2 py-6 md:min-w-fit lg:mt-0 lg:px-2 lg:py-8">
        <WeekCalendar
          onSelectDate={handleDateSelect}
          timestamps={prescriberTimestamps}
          selectedDateTime={selectedDateTime}
        />

        <div className="mt-auto flex items-center justify-between px-13">
          <Typography.Text type="paragraphTwo" muted>
            {selectedDateTime
              ? `Data selecionada: ${formatDate(selectedDateTime, 'dd/MM/yyyy - HH:mm')}`
              : 'Selecione uma data e hora'}
          </Typography.Text>
          <Button onClick={handleAppointmentSchedule} disabled={!selectedDateTime} className="bg-primary-700">
            Agendar
          </Button>
        </div>
      </div>
    </div>
  )
}
