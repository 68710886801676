import { Typography } from '@cannect/new-components/atoms'
import { CustomSelect } from '@cannect/new-components/atoms/Form/CustomSelect'
import { PrescriberDetailCard, SchedulingFeaturesSections } from '@cannect/new-components/organisms'
import {
  TSchedulingFilterByType,
  TSchedulingFilterSortType,
  useGetAvailablePrescribers
} from '@cannect/services/resources/scheduling'
import { Skeleton } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import FilterForm from './FilterForm'

export default function FilterByStep() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const filterBy = params.get('filter_by') as TSchedulingFilterByType
  const filterById = params.get('filter_by_id')

  const [sortBy, setSortBy] = useState<TSchedulingFilterSortType>('available_date_times')

  const currentFilterBy = {
    type: filterBy || 'expertise',
    value: filterById || undefined
  }

  const { data: availablePrescribers, isFetching: loadingPrescribers } = useGetAvailablePrescribers({
    filterBy: currentFilterBy,
    sortBy,
    orderBy: 'asc'
  })

  const prescribersList = availablePrescribers?.prescribers || []
  const showPrescribersList = prescribersList.length > 0

  const SORT_OPTIONS = [
    { label: 'Ordem alfabética', value: 'professional_name' },
    { label: 'Menor valor de consulta', value: 'online_appointment_value' },
    { label: 'Horários disponíveis', value: 'available_date_times' }
  ]

  useEffect(() => {
    setSortBy('available_date_times')
  }, [filterBy, filterById])

  return (
    <div className="w-full max-w-full bg-secondary-100">
      <section
        className={showPrescribersList ? 'bg-[#D0D7A7]' : 'bg-scheduling-hero-desktop bg-cover bg-center bg-no-repeat'}>
        <div
          className={`container flex max-w-[1164px] flex-col-reverse items-center justify-start gap-8 px-4 transition-all md:flex-row md:justify-between ${!showPrescribersList ? 'min-h-[calc(100dvh-74px)] py-5 md:min-h-[calc(100dvh-106px)] md:py-24' : 'pb-8 pt-12'}`}>
          <div className="flex w-full flex-col gap-6">
            <AnimatePresence initial={false} mode="popLayout">
              {!showPrescribersList && (
                <motion.div
                  key="hero-content"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.3,
                    exit: { duration: 0.1 }
                  }}>
                  <Typography.Heading type="headingTwo" weight="semibold" className="leading-10 text-primary">
                    Sua consulta em poucos cliques
                  </Typography.Heading>
                  <Typography.Text className="text-primary">
                    Bem-vindo(a) ao nosso agendamento digital! Encontre <br />
                    um especialista na terapia canabinoide a partir de R$ 199,00.
                  </Typography.Text>
                </motion.div>
              )}
            </AnimatePresence>

            <FilterForm isLoading={loadingPrescribers} showFilterByDate={showPrescribersList} />

            <AnimatePresence initial={false}>
              {showPrescribersList && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="mt-4 flex items-center justify-between gap-2">
                  <div className="flex w-full max-w-fit flex-col -space-y-2 md:flex-row md:items-center md:gap-2 md:space-y-0">
                    <Typography.Text>Ordenar por:</Typography.Text>
                    <CustomSelect
                      value={SORT_OPTIONS.find((opt) => opt.value === sortBy)}
                      onChange={(option) => option && setSortBy(option.value as TSchedulingFilterSortType)}
                      options={SORT_OPTIONS}
                      placeholder="Ordenar por"
                      variant="outlined"
                      customClassNames={{
                        control: 'bg-transparent border-none px-0 h-auto gap-1 text-base font-semibold',
                        dropdownIndicator: 'text-gray-800',
                        singleValue: 'text-neutral-800 text-base',
                        placeholder: 'text-gray-800'
                      }}
                    />
                  </div>
                  <Typography.Text className="text-right">
                    <strong>{prescribersList?.length}</strong> profission
                    {prescribersList.length === 1 ? 'al' : 'ais'} encontrado
                    {prescribersList.length === 1 ? '' : 's'}
                  </Typography.Text>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </section>

      <AnimatePresence initial={false}>
        {showPrescribersList ? (
          <motion.div
            key="prescribers-list"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="container mx-auto flex w-full flex-col py-8">
            {loadingPrescribers ? (
              <div className="w-full">
                <Skeleton active paragraph={{ rows: 4 }} round />
              </div>
            ) : (
              <div className="mx-auto max-w-[1164px] space-y-16">
                {prescribersList.length > 0 ? (
                  prescribersList.map((prescriber, index) => (
                    <motion.div
                      key={prescriber.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{
                        duration: 0.5,
                        delay: index * 0.1
                      }}>
                      <PrescriberDetailCard prescriberInfo={prescriber} />
                    </motion.div>
                  ))
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    className="flex h-40 items-center justify-center rounded-lg border border-neutral-200">
                    <Typography.Text type="paragraphOne" muted>
                      Nenhum profissional encontrado.
                    </Typography.Text>
                  </motion.div>
                )}
              </div>
            )}
          </motion.div>
        ) : (
          <motion.div
            key="features-section"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, exit: { duration: 0.1 } }}>
            <SchedulingFeaturesSections />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
